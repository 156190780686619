.feedback-form{
    .sm-block({
        max-width: 450px;
    });
    .xs-block({
        max-width: ~'calc(100% - 30px)';
    });
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &_inp{
        .sm-block({
            height: 55px;
            padding: 17px 20px;
            margin-bottom: 25px;
        });
        .xxs-block({
            margin-bottom: 15px;
        });
        width: 100%;
        margin-bottom: 32px;
        background: @white;
        border-radius: 6px;
        padding: 21px 32px;
        height: 63px;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: @black;
        &::placeholder{
            color: rgba(47, 47, 105, 0.5);
        }
        &:nth-child(1), &:nth-child(2){
            .sm-block({
                width: ~'calc(100% / 2 - 12.5px)';
            });
            .xxs-block({
                width: 100%;
            });
            width: ~'calc(100% / 2 - 16px)';
        }   
    }
    textarea{
        .sm-block({
            height: 120px;
        });
        height: 159px;
    }
    &_btn{
        .sm-block({
            height: 55px;
        });
        height: 63px;
        width: 100%;
        background: @darkblue;
        &:hover{
            color: @darkblue;
            background: @white;
        }
    }
}

.block-contacts{
    .sm-block({
        padding: 35px 0 30px;
    });
    padding: 50px 0 30px;
    background: #F6F8FE;
}

.contacts{
    max-width: 823px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    background: @white;
    border-radius: 16px;
    padding: 15px;
    &_left{
        .md-block({
            padding: 25px 30px 25px 20px;
        });
        .sm-block({
            width: 100%;
        });
        .xxs-block({
            padding: 25px 15px;
        });
        .xxxs-block({
            padding: 25px 5px;
        }); 
        width: 50%;
        padding: 25px 30px 25px 45px;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }   
    &_phone{
        .md-block({
            font-size: 28px;
            line-height: 36px;
        });
        .xxxs-block({
            font-size: 24px;
            line-height: 30px;
        });
        font-weight: 600;
        font-size: 32px;
        line-height: 41px;
        color: @darkblue;
        margin-bottom: 10px;
        &:hover{
            color: @blue;
        }
    }
    &_mail{
        .xxxs-block({
            font-size: 18px;
            line-height: 24px;
        });
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
        color: @darkblue;
        margin-bottom: 10px;
        &:hover{
            color: @blue;
        }
    }
    &_address{
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: @darkblue;
        opacity: 0.8;
        margin-top: auto;
        &:hover{
            color: @blue;
        }
    }
    &_right{
        .sm-block({
            width: 100%;
        });
        width: 50%;
        border-radius: 10px;
        overflow: hidden;
        height: 320px;
        iframe{
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
}

.block-page-title3{
    .page-title_img{
        .lg-block({
            margin-right: 30px;
        });
        .md-block({
            margin-right: 15px;
        });
        margin-right: 52px;
    }
}
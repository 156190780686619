.block-news-page{
    .xl-block({
        padding: 70px 0;
    });
    .xs-block({
        padding: 60px 0;
    });
    background: #F6F8FE;
    padding: 80px 0 90px;
}

.news-page{
    .xs-block({
        margin: 0 -15px -30px;
    });
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -50px;
    .news-item{
        .md-block({
            width: ~'calc(100% / 2 - 30px)';
        });
        .to(500px, {
            width: ~'calc(100% - 30px)';
            margin: 0 15px 30px;
        });
        margin: 0 15px 50px;
        width: ~'calc(100% / 3 - 30px)';
    }
}

.block-page-title{
    .md-block({
        padding: 15px 0 30px;
    });
    padding: 25px 0 50px;
}

.page-title{
    display: flex;
    align-items: flex-start;
    &_img{
        .md-block({
            width: 70px;
            height: 70px;
            border-radius: 20px;
            margin-right: 15px;
        });
        .sm-block({
            width: 60px;
            height: 60px;
            border-radius: 15px;
        });
        .xxxs-block({
            width: 50px;
            height: 50px;
            border-radius: 10px;
        });
        flex-shrink: 0;
        margin-right: 30px;
        width: 90px;
        height: 90px;
        background: linear-gradient(211.91deg, rgba(24, 115, 211, 0.1) 9.53%, rgba(24, 115, 211, 0) 117.86%);
        border-radius: 25px;
        .center();
        img{
            .md-block({
                transform: scale(0.8);
            });
            .xxxs-block({
                transform: scale(0.6);
            });
        }
    }
    &_bc{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 8px;
        a{
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.065em;
            text-transform: uppercase;
            color: #093B4E;
            opacity: 0.5;
            display: flex;
            align-items: center;
            &:hover{
                color: @blue;
                opacity: 1;
            }
            &::after{
                content: '/';
                display: block;
                margin: 0 5px;
            }
            &:last-child{
                &::after{
                    display: none;
                }
            }
        }
    }
    &_name{
        .lg-block({
            font-size: 40px;
            line-height: 50px;
        });
        .xl-block({
            font-size: 34px;
            line-height: 40px;
        });
        .md-block({
            font-size: 30px;
            line-height: 36px;
        });
        .sm-block({
            font-size: 24px;
            line-height: 30px;
        });
        .xxxs-block({
            font-size: 20px;
            line-height: 24px;
        });
        font-weight: 500;
        font-size: 44px;
        line-height: 56px;
        color: @darkblue;
    }
    &_date{
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 133.5%;
        color: rgba(0,0,0,0.6);
        img{
            flex-shrink: 0;
            margin-right: 16px;
        }
    }
}

.block-page-title2{
    background: #F6F8FE;
    .page-title_img{
        .lg-block({
            margin-right: 30px;
        });
        .md-block({
            margin-right: 15px;
        });
        margin-right: 58px;
    }
    .page-title_name{
        .xl-block({
            font-size: 34px;
            line-height: 40px;
        });
        .md-block({
            font-size: 30px;
            line-height: 36px;
        });
        .sm-block({
            font-size: 24px;
            line-height: 30px;
        });
        .xxxs-block({
            font-size: 20px;
            line-height: 24px;
        });
        max-width: 790px;
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 8px;
    }
}

.block-news-single{
    .sm-block({
        padding: 35px 0 70px;
    });
    .xs-block({
        padding: 35px 0 60px;
    });
    padding: 35px 0 83px;
}

.news-single{
    max-width: 823px;
    margin: 0 auto;
    img{
        .xl-block({
            margin-bottom: 30px;
        });
        .xs-block({
            border-radius: 10px;
        });
        width: 100%;
        border-radius: 16px;
        margin-bottom: 48px;
    }
    p{
        .xl-block({
            margin-bottom: 30px;
            font-size: 18px;
        });
        .xs-block({
            font-size: 16px;
        });
        font-size: 24px;
        line-height: 150%;
        color: @darkblue;
        opacity: 0.6;
        margin-bottom: 48px;
    }
    .btn{
        .xs-block({
            height: 55px;
            width: 220px;
        });
        width: 228px;
        height: 65px;
        background: @darkblue;
        svg{
            margin-left: 10px;
            path{
                fill: @white;
            }
        }
        &:hover{
            background: @blue;
        }
    }
}
.block-products{
    .sm-block({
        padding: 70px 0;
    });
    .xs-block({
        padding: 60px 0;
    });
    padding: 90px 0;
    background: #F6F8FE;
}

.products_title{
    .md-block({
        font-size: 34px;
        line-height: 40px;
    });
    .xs-block({
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
    });
    font-weight: 500;
    font-size: 44px;
    line-height: 57px;
    color: @darkblue;
    margin-bottom: 38px;
}

.products_text{
    .md-block({
        font-size: 18px;
        line-height: 24px;
    });
    .xs-block({
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
    });
    font-size: 22px;
    line-height: 28px;
    max-width: 730px;
    color: @darkblue;
    opacity: 0.6;
    margin-bottom: 43px;
}

.products{
    .xs-block({
        margin-bottom: -30px;
    });
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -40px;
}

.product{
    .xl-block({
        padding: 30px 40px;
    });
    .sm-block({
        width: ~'calc(100% - 30px)';
    });
    .xs-block({
        margin-bottom: 30px;
    });
    .xxs-block({
        padding: 30px;
    });
    width: ~'calc(100% / 2 - 30px)';
    margin: 0 15px 40px;
    padding: 40px 64px;
    background: @white;
    border-radius: 16px;
    &_img{
        .xxs-block({
            margin-bottom: 20px;
        });
        width: 90px;
        height: 90px;
        border: 1px solid rgba(255,255,255,0.5);
        background: linear-gradient(211.91deg, rgba(24, 115, 211, 0.1) 9.53%, rgba(24, 115, 211, 0) 117.86%);
        border-radius: 25px;
        .center();
        margin-bottom: 32px;
    }
    &_title{
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 16px;
    }
    &_text{
        font-size: 14px;
        line-height: 133.5%;
        opacity: 0.6;
    }
}

.products_more{
    .sm-block({
        margin-top: 70px;
    });
    .xs-block({
        margin-top: 60px;
    });
    margin-top: 90px;
    display: flex;
    justify-content: center;
    a{
        width: 90px;
        height: 90px;
        border: 1px solid rgba(255,255,255,0.5);
        background: linear-gradient(211.91deg, rgba(24, 115, 211, 0.1) 9.53%, rgba(24, 115, 211, 0) 117.86%);
        border-radius: 25px;
        .center();
        margin-bottom: 32px;
    }
}
@charset "UTF-8";

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */

.slick-loading .slick-list {
  background: #fff url('./ajax-loader.gif') center center no-repeat;
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Icons */
}

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/slick.eot');
  src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick.woff') format('woff'), url('../fonts/slick.ttf') format('truetype'), url('../fonts/slick.svg#slick') format('svg');
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */

.slick-dotted .slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.tooltipster-fall,
.tooltipster-grow.tooltipster-show {
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-base {
  display: flex;
  pointer-events: none;
  position: absolute;
}

.tooltipster-box {
  flex: 1 1 auto;
}

.tooltipster-content {
  box-sizing: border-box;
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
}

.tooltipster-ruler {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
}

.tooltipster-fade {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  -ms-transition-property: opacity;
  transition-property: opacity;
}

.tooltipster-fade.tooltipster-show {
  opacity: 1;
}

.tooltipster-grow {
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-backface-visibility: hidden;
}

.tooltipster-grow.tooltipster-show {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-swing {
  opacity: 0;
  -webkit-transform: rotateZ(4deg);
  -moz-transform: rotateZ(4deg);
  -o-transform: rotateZ(4deg);
  -ms-transform: rotateZ(4deg);
  transform: rotateZ(4deg);
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
}

.tooltipster-swing.tooltipster-show {
  opacity: 1;
  -webkit-transform: rotateZ(0);
  -moz-transform: rotateZ(0);
  -o-transform: rotateZ(0);
  -ms-transform: rotateZ(0);
  transform: rotateZ(0);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 1);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -moz-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -ms-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -o-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
}

.tooltipster-fall {
  -webkit-transition-property: top;
  -moz-transition-property: top;
  -o-transition-property: top;
  -ms-transition-property: top;
  transition-property: top;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-fall.tooltipster-initial {
  top: 0 !important;
}

.tooltipster-fall.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  top: 0!important;
  opacity: 0;
}

.tooltipster-slide {
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  -ms-transition-property: left;
  transition-property: left;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-slide.tooltipster-initial {
  left: -40px !important;
}

.tooltipster-slide.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  left: 0!important;
  opacity: 0;
}

@keyframes tooltipster-fading {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tooltipster-update-fade {
  animation: tooltipster-fading 0.4s;
}

@keyframes tooltipster-rotating {
  25% {
    transform: rotate(-2deg);
  }

  75% {
    transform: rotate(2deg);
  }

  100% {
    transform: rotate(0);
  }
}

.tooltipster-update-rotate {
  animation: tooltipster-rotating 0.6s;
}

@keyframes tooltipster-scaling {
  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.tooltipster-update-scale {
  animation: tooltipster-scaling 0.6s;
}

.tooltipster-sidetip .tooltipster-box {
  background: #565656;
  border: 2px solid #000;
  border-radius: 4px;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
  margin-top: 8px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-box {
  margin-right: 8px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-box {
  margin-left: 8px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-box {
  margin-bottom: 8px;
}

.tooltipster-sidetip .tooltipster-content {
  color: #fff;
  line-height: 18px;
  padding: 6px 14px;
}

.tooltipster-sidetip .tooltipster-arrow {
  overflow: hidden;
  position: absolute;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow {
  height: 10px;
  margin-left: -10px;
  top: 0;
  width: 20px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  right: 0;
  top: 0;
  width: 10px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  left: 0;
  top: 0;
  width: 10px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow {
  bottom: 0;
  height: 10px;
  margin-left: -10px;
  width: 20px;
}

.tooltipster-sidetip .tooltipster-arrow-background,
.tooltipster-sidetip .tooltipster-arrow-border {
  height: 0;
  position: absolute;
  width: 0;
}

.tooltipster-sidetip .tooltipster-arrow-background {
  border: 10px solid transparent;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: #565656;
  left: 0;
  top: 3px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #565656;
  left: -3px;
  top: 0;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
  border-right-color: #565656;
  left: 3px;
  top: 0;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #565656;
  left: 0;
  top: -3px;
}

.tooltipster-sidetip .tooltipster-arrow-border {
  border: 10px solid transparent;
  left: 0;
  top: 0;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #000;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border {
  border-left-color: #000;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border {
  border-right-color: #000;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #000;
}

.tooltipster-sidetip .tooltipster-arrow-uncropped {
  position: relative;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-uncropped {
  top: -10px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-uncropped {
  left: -10px;
}

@font-face {
  font-family: "TT";
  src: url(../fonts/TTFirsNeue-Light.ttf);
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "TT";
  src: url(../fonts/TTFirsNeue-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "TT";
  src: url(../fonts/TTFirsNeue-Medium.ttf);
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "TT";
  src: url(../fonts/TTFirsNeue-DemiBold.ttf);
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "TT";
  src: url(../fonts/TTFirsNeue-Bold.ttf);
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

* {
  box-sizing: border-box;
}

a,
input,
textarea,
button {
  border: none;
  outline: none;
  background: none;
  transition: 0.5s;
  font-family: "TT";
  text-decoration: none;
  padding: 0;
  color: inherit;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

svg path {
  transition: 0.5s;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "TT";
  color: #000;
  background: #ffffff;
  line-height: 1;
  font-weight: 400;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  position: relative;
}

main {
  padding-top: 115px;
  max-width: 100%;
  overflow: hidden;
}

.btn {
  background: #4F98E4;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.btn:hover {
  background: #2F2F69;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background: #ffffff;
  padding: 24px 0;
  transition: 0.5s;
}

.header--scroll {
  padding: 10px 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_logo {
  max-width: 158px;
  flex-shrink: 0;
}

.header_logo img {
  width: 100%;
}

.header_nav {
  display: flex;
  align-items: center;
}

.header_nav li {
  margin: 0 15px;
}

.header_nav li a {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.065em;
  text-transform: uppercase;
  color: #093B4E;
}

.header_nav li a:hover {
  color: #4F98E4;
}

.header_btn {
  width: 175px;
  height: 51px;
  background: #2F2F69;
  flex-shrink: 0;
}

.header_btn:hover {
  background: #4F98E4;
}

.header_burger {
  display: none;
  position: relative;
  width: 30px;
  height: 20px;
  margin-left: 30px;
  cursor: pointer;
}

.header_burger span {
  display: block;
  width: 100%;
  position: absolute;
  background: #2F2F69;
  height: 2px;
  left: 0;
  transition: 0.5s;
}

.header_burger span:nth-child(1) {
  top: 0;
}

.header_burger span:nth-child(2) {
  top: 9px;
}

.header_burger span:nth-child(3) {
  top: 18px;
}

.header_burger--active span:nth-child(1) {
  top: 9px;
  transform: rotate(45deg);
}

.header_burger--active span:nth-child(2) {
  opacity: 0;
}

.header_burger--active span:nth-child(3) {
  top: 9px;
  transform: rotate(-45deg);
}

footer {
  background: #2F2F69;
  padding-top: 175px;
}

.footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: #FFFFFF;
}

.footer_col {
  margin-bottom: 59px;
}

.footer_col:nth-child(1) {
  width: 190px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.footer_col:nth-child(2) {
  width: 160px;
}

.footer_col:nth-child(3) {
  width: 160px;
}

.footer_col:nth-child(4) {
  width: 310px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer_onec {
  margin-bottom: -10px;
}

.footer_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 25px;
}

.footer_nav {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.065em;
  text-transform: uppercase;
}

.footer_nav li {
  margin-bottom: 32px;
}

.footer_nav li:last-child {
  margin-bottom: 0;
}

.footer_nav li a {
  opacity: 0.8;
}

.footer_nav li a:hover {
  opacity: 1;
}

.footer_phone {
  font-weight: 600;
  font-size: 32px;
  line-height: 41px;
  margin: -4px 0 6px;
  display: block;
}

.footer_phone:hover {
  color: #4F98E4;
}

.footer_mail {
  display: block;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  margin-bottom: 24px;
}

.footer_mail:hover {
  color: #4F98E4;
}

.footer_btn {
  width: 190px;
  height: 51px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  margin-bottom: 33px;
}

.footer_address {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  opacity: 0.8;
}

.footer_address:hover {
  opacity: 1;
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.065em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.footer-bottom_copy {
  opacity: 0.7;
}

.footer-bottom_link {
  opacity: 0.7;
}

.footer-bottom_link:hover {
  opacity: 1;
}

.popUp {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: none;
}

.popUp::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.popUp::-webkit-scrollbar-thumb {
  width: 0;
  height: 0;
}

.popUp_content {
  position: relative;
  z-index: 99;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 30px;
}

.popUp_content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.popUp_content::-webkit-scrollbar-thumb {
  width: 0;
  height: 0;
}

.popUp_form {
  max-width: 515px;
  width: 100%;
  background: #ffffff;
  position: relative;
  z-index: 99;
  padding: 65px 40px;
}

.popUp_cross {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.popUp_cross:hover svg path {
  fill: #4F98E4;
}

.popUp_title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
}

.popUp form {
  text-align: center;
}

.popUp_inp {
  background: #ffffff;
  width: 100%;
  max-width: 350px;
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  padding: 0 20px;
  margin: 0 auto 15px;
  color: #000;
  border: 1px solid #2F2F69;
}

.popUp_inp::placeholder {
  color: #C4C4C4;
}

.popUp_btn {
  height: 50px;
  width: 100%;
  max-width: 350px;
  margin: 20px auto 0;
}

.popUp_layer {
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.block-offer {
  padding: 15px 0 60px;
}

.offer {
  display: flex;
  align-items: center;
  margin: 0 -15px;
}

.offer_left {
  width: calc(100% / 12 * 7 - 30px);
  margin: 0 15px;
}

.offer_title {
  font-weight: 500;
  font-size: 44px;
  line-height: 57px;
  color: #2F2F69;
  margin-bottom: 30px;
}

.offer_text {
  font-size: 22px;
  line-height: 28px;
  color: #161616;
  opacity: 0.5;
  margin-bottom: 60px;
  max-width: 568px;
}

.offer_btns {
  display: flex;
}

.offer_btns button {
  width: 221px;
  height: 66px;
}

.offer_btns button:nth-child(1) {
  margin-right: 30px;
}

.offer_btns button:nth-child(2) {
  color: #4F98E4;
  background: rgba(79, 152, 228, 0.2);
}

.offer_btns button:nth-child(2):hover {
  color: #ffffff;
  background: #2F2F69;
}

.offer_right {
  width: calc(100% / 12 * 5 - 30px);
  margin: 0 15px;
}

.offer_right svg {
  width: 894px;
  max-width: none;
}

.block-about {
  background: #4F98E4;
  padding: 76px 0;
}

.about_title {
  font-weight: 500;
  font-size: 38px;
  line-height: 49px;
  margin-bottom: 26px;
  color: #ffffff;
}

.about {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -15px;
  color: #ffffff;
}

.about_left {
  width: calc(100% / 2 - 30px);
  margin: 0 15px;
  font-size: 23px;
  line-height: 150%;
}

.about_left a {
  text-decoration: underline;
}

.about_left a:hover {
  color: #2F2F69;
}

.about_right {
  width: calc(100% / 2 - 30px);
  margin: 0 15px;
}

.about_right p {
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 20px;
}

.about_right p:last-child {
  margin-bottom: 0;
}

.block-news {
  padding: 90px 0 100px;
  background: #F6F8FE;
}

.news_title {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 38px;
  line-height: 49px;
  color: #2F2F69;
}

.news .arrows {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.news .arrows_left {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #4F98E4;
  margin-right: 20px;
  padding-right: 2px;
  transition: 0.5s;
  cursor: pointer;
}

.news .arrows_left:hover {
  background: #4F98E4;
}

.news .arrows_left:hover svg path {
  fill: #ffffff;
}

.news .arrows_right {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #4F98E4;
  padding-left: 2px;
  transition: 0.5s;
  cursor: pointer;
}

.news .arrows_right svg path {
  fill: #2F2F69;
}

.news .arrows_right:hover {
  background: #4F98E4;
}

.news .arrows_right:hover svg path {
  fill: #ffffff;
}

.news-slider {
  margin: 0 -15px;
}

.news-slider .slick-slide {
  margin: 0 15px;
}

.news-slider .slick-track {
  display: flex;
}

.news-item {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
}

.news-item_img {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 16px;
}

.news-item_img img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.news-item_date {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 133.5%;
  color: rgba(0, 0, 0, 0.6);
}

.news-item_date img {
  margin-right: 16px;
  margin-top: -4px;
}

.news-item_title {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  margin-bottom: 26px;
  display: block;
}

.news-item_title:hover {
  color: #4F98E4;
}

.news-item_link {
  margin-top: auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 133.5%;
  color: #4F98E4;
}

.news-item_link svg {
  margin-left: 9px;
  margin-bottom: -2px;
}

.news-item_link:hover {
  color: #2F2F69;
}

.news-item_link:hover svg path {
  fill: #2F2F69;
}

.block-feedback {
  background: #F6F8FE;
}

.block-feedback2 {
  background: #ffffff;
}

.feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  background-color: #4F98E4;
  background-image: url('../img/photo/5.png');
  background-position: left 30px bottom;
  background-repeat: no-repeat;
  background-size: 191px 273px;
  max-width: 823px;
  margin: 0 auto;
  box-shadow: 2px 20px 30px rgba(0, 0, 78, 0.15);
  border-radius: 24px;
  margin-bottom: -125px;
  position: relative;
  z-index: 1;
  color: #ffffff;
}

.feedback_text {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  opacity: 0.8;
  margin-bottom: 32px;
}

.feedback_title {
  font-weight: 500;
  font-size: 32px;
  line-height: 133.5%;
  text-align: center;
  margin-bottom: 32px;
  max-width: 607px;
}

.feedback_btn {
  background-color: #2F2F69;
  width: 191px;
  height: 64px;
}

.feedback_btn:hover {
  background: #ffffff;
  color: #2F2F69;
}

.block-licenses {
  padding: 90px 0 240px;
}

.licenses_title {
  margin-bottom: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 38px;
  line-height: 49px;
  color: #2F2F69;
}

.licenses {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -30px;
}

.license {
  width: calc(100% / 6 - 30px);
  margin: 0 15px 30px;
  box-shadow: 0px 5px 6px rgba(0, 0, 78, 0.04);
  background: #ffffff;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  transition: 0.5s;
}

.license_icon {
  margin-bottom: 60px;
}

.license_inf {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #2F2F69;
  min-height: 36px;
  width: 100%;
}

.license_inf svg {
  width: 24px;
  flex-shrink: 0;
  margin-left: 10px;
}

.license_title {
  transition: 0.5s;
}

.license--active {
  background: #4F98E4;
}

.license--active .license_title {
  color: #ffffff;
}

.license--active svg path {
  fill: #ffffff;
  opacity: 1;
}

.tooltipster-content {
  background: #E8F1FB;
  border-radius: 8px;
  border: none !important;
  padding: 18px 20px !important;
  font-size: 16px;
  line-height: 21px !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

.tooltipster-sidetip .tooltipster-box {
  border: none !important;
  background: transparent;
}

.tooltipster-arrow {
  border: none !important;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #E8F1FB;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border: none !important;
}

.block-eco {
  padding-bottom: 120px;
}

.eco {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eco_left {
  width: 230px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.eco_left p {
  width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 38px;
  line-height: 49px;
  color: #2F2F69;
}

.eco_left span {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #4F98E4;
  margin-right: 9px;
}

.eco_center {
  width: 422px;
  flex-shrink: 0;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}

.eco_center .gif {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.eco_center img {
  position: relative;
  z-index: 2;
  width: 100%;
}

.eco_right {
  width: 230px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.eco_right p {
  width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 38px;
  line-height: 49px;
  color: #2F2F69;
  text-align: right;
}

.eco_right span {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #4F98E4;
  margin-left: 9px;
}

.block-projects {
  padding: 120px 0 150px;
  background: #F6F8FE;
}

.projects_title {
  font-weight: 500;
  font-size: 38px;
  line-height: 49px;
  text-align: center;
  color: #2F2F69;
  margin-bottom: 50px;
}

.projects {
  margin: 0 -15px -30px;
  display: flex;
  flex-wrap: wrap;
}

.project {
  width: calc(100% / 3 - 30px);
  margin: 0 15px 30px;
  background: #ffffff;
  border-radius: 16px;
  padding: 36px 41px 83px;
  position: relative;
  overflow: hidden;
  transition: 0.5s;
}

.project:hover {
  background: #4F98E4;
  transform: translateY(-15px);
}

.project:hover .project_img img:nth-child(1) {
  opacity: 0;
}

.project:hover .project_img img:nth-child(2) {
  opacity: 1;
}

.project:hover .project_title,
.project:hover .project_text {
  color: #ffffff;
}

.project:hover .project_link {
  opacity: 1;
}

.project_img {
  width: 90px;
  height: 90px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(211.91deg, rgba(24, 115, 211, 0.1) 9.53%, rgba(24, 115, 211, 0) 117.86%);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  position: relative;
}

.project_img img {
  transition: 0.5s;
}

.project_img img:nth-child(1) {
  opacity: 1;
}

.project_img img:nth-child(2) {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.project_title {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #000;
  margin-bottom: 16px;
  transition: 0.5s;
}

.project_text {
  font-size: 14px;
  line-height: 133.5%;
  color: #000;
  opacity: 0.6;
  transition: 0.5s;
}

.project_link {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 57px;
  padding: 0 41px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 133.5%;
  color: #ffffff;
  background: #2F2F69;
  opacity: 0;
}

.project_link svg path {
  fill: #ffffff;
}

.project-last {
  border: 2px dashed rgba(47, 47, 105, 0.5);
  background: transparent !important;
  transform: none !important;
}

.project-last_img {
  background: none;
  border-radius: 0;
  width: calc(100% + 82px);
  margin-left: -41px;
  border: none;
  margin-bottom: 26px;
}

.project-last_img img {
  opacity: 1 !important;
}

.project-last_title {
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #000 !important;
}

.project-last_link {
  width: calc(100% - 42px);
  left: 22px;
  opacity: 1;
  background: #FFFFFF;
  border-radius: 16px;
  color: #2F2F69;
  bottom: 22px;
}

.project-last_link svg path {
  fill: #2F2F69;
}

.project-last_link:hover {
  background: #4F98E4;
  color: #ffffff;
}

.project-last_link:hover svg path {
  fill: #ffffff;
}

.block-news-page {
  background: #F6F8FE;
  padding: 80px 0 90px;
}

.news-page {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -50px;
}

.news-page .news-item {
  margin: 0 15px 50px;
  width: calc(100% / 3 - 30px);
}

.block-page-title {
  padding: 25px 0 50px;
}

.page-title {
  display: flex;
  align-items: flex-start;
}

.page-title_img {
  flex-shrink: 0;
  margin-right: 30px;
  width: 90px;
  height: 90px;
  background: linear-gradient(211.91deg, rgba(24, 115, 211, 0.1) 9.53%, rgba(24, 115, 211, 0) 117.86%);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-title_bc {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.page-title_bc a {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.065em;
  text-transform: uppercase;
  color: #093B4E;
  opacity: 0.5;
  display: flex;
  align-items: center;
}

.page-title_bc a:hover {
  color: #4F98E4;
  opacity: 1;
}

.page-title_bc a::after {
  content: '/';
  display: block;
  margin: 0 5px;
}

.page-title_bc a:last-child::after {
  display: none;
}

.page-title_name {
  font-weight: 500;
  font-size: 44px;
  line-height: 56px;
  color: #2F2F69;
}

.page-title_date {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 133.5%;
  color: rgba(0, 0, 0, 0.6);
}

.page-title_date img {
  flex-shrink: 0;
  margin-right: 16px;
}

.block-page-title2 {
  background: #F6F8FE;
}

.block-page-title2 .page-title_img {
  margin-right: 58px;
}

.block-page-title2 .page-title_name {
  max-width: 790px;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 8px;
}

.block-news-single {
  padding: 35px 0 83px;
}

.news-single {
  max-width: 823px;
  margin: 0 auto;
}

.news-single img {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 48px;
}

.news-single p {
  font-size: 24px;
  line-height: 150%;
  color: #2F2F69;
  opacity: 0.6;
  margin-bottom: 48px;
}

.news-single .btn {
  width: 228px;
  height: 65px;
  background: #2F2F69;
}

.news-single .btn svg {
  margin-left: 10px;
}

.news-single .btn svg path {
  fill: #ffffff;
}

.news-single .btn:hover {
  background: #4F98E4;
}

.block-products {
  padding: 90px 0;
  background: #F6F8FE;
}

.products_title {
  font-weight: 500;
  font-size: 44px;
  line-height: 57px;
  color: #2F2F69;
  margin-bottom: 38px;
}

.products_text {
  font-size: 22px;
  line-height: 28px;
  max-width: 730px;
  color: #2F2F69;
  opacity: 0.6;
  margin-bottom: 43px;
}

.products {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -40px;
}

.product {
  width: calc(100% / 2 - 30px);
  margin: 0 15px 40px;
  padding: 40px 64px;
  background: #ffffff;
  border-radius: 16px;
}

.product_img {
  width: 90px;
  height: 90px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(211.91deg, rgba(24, 115, 211, 0.1) 9.53%, rgba(24, 115, 211, 0) 117.86%);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.product_title {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 16px;
}

.product_text {
  font-size: 14px;
  line-height: 133.5%;
  opacity: 0.6;
}

.products_more {
  margin-top: 90px;
  display: flex;
  justify-content: center;
}

.products_more a {
  width: 90px;
  height: 90px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(211.91deg, rgba(24, 115, 211, 0.1) 9.53%, rgba(24, 115, 211, 0) 117.86%);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.feedback-form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.feedback-form_inp {
  width: 100%;
  margin-bottom: 32px;
  background: #ffffff;
  border-radius: 6px;
  padding: 21px 32px;
  height: 63px;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #000;
}

.feedback-form_inp::placeholder {
  color: rgba(47, 47, 105, 0.5);
}

.feedback-form_inp:nth-child(1),
.feedback-form_inp:nth-child(2) {
  width: calc(100% / 2 - 16px);
}

.feedback-form textarea {
  height: 159px;
}

.feedback-form_btn {
  height: 63px;
  width: 100%;
  background: #2F2F69;
}

.feedback-form_btn:hover {
  color: #2F2F69;
  background: #ffffff;
}

.block-contacts {
  padding: 50px 0 30px;
  background: #F6F8FE;
}

.contacts {
  max-width: 823px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 16px;
  padding: 15px;
}

.contacts_left {
  width: 50%;
  padding: 25px 30px 25px 45px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contacts_phone {
  font-weight: 600;
  font-size: 32px;
  line-height: 41px;
  color: #2F2F69;
  margin-bottom: 10px;
}

.contacts_phone:hover {
  color: #4F98E4;
}

.contacts_mail {
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #2F2F69;
  margin-bottom: 10px;
}

.contacts_mail:hover {
  color: #4F98E4;
}

.contacts_address {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #2F2F69;
  opacity: 0.8;
  margin-top: auto;
}

.contacts_address:hover {
  color: #4F98E4;
}

.contacts_right {
  width: 50%;
  border-radius: 10px;
  overflow: hidden;
  height: 320px;
}

.contacts_right iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.block-page-title3 .page-title_img {
  margin-right: 52px;
}

.block-its-offer {
  padding: 60px 0 130px;
}

.its-offer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
}

.its-offer_left {
  margin: 0 15px;
  width: calc(100% / 12 * 7 - 30px);
}

.its-offer_title {
  font-weight: 500;
  font-size: 44px;
  line-height: 57px;
  color: #2F2F69;
  max-width: 600px;
  margin-bottom: 38px;
}

.its-offer_text {
  font-size: 22px;
  line-height: 28px;
  color: #2F2F69;
  opacity: 0.6;
}

.its-offer_right {
  margin: 0 15px;
  width: calc(100% / 12 * 5 - 30px);
  text-align: center;
}

.its-offer_right img {
  width: 100%;
  max-width: 342px;
}

.block-its-about {
  padding: 60px 0;
  background: #4F98E4;
  color: #FFFFFF;
}

.its-about_title {
  font-weight: 500;
  font-size: 38px;
  line-height: 49px;
  margin-bottom: 26px;
  max-width: 490px;
}

.its-about_text {
  max-width: 740px;
}

.its-about_text p {
  font-size: 18px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 26px;
}

.its-about_text p b {
  color: #ffffff;
  font-weight: 700;
}

.its-about_text p:last-child {
  margin-bottom: 0;
}

.block-its-tarifs {
  padding: 60px 0;
}

.its-tarifs_title {
  margin-bottom: 50px;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: #2F2F69;
  max-width: 730px;
}

.its-tarifs_table {
  overflow-x: scroll;
}

.its-tarifs_table::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.its-tarifs_table::-webkit-scrollbar-thumb {
  width: 0;
  height: 0;
}

.its-tarifs_row {
  padding: 16px 18px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 18px;
  line-height: 23px;
}

.its-tarifs_row:nth-child(1) {
  color: #2F2F69;
  font-weight: 500;
}

.its-tarifs_row:nth-child(even) {
  background: #F6F8FE;
}

.its-tarifs_row:last-child {
  margin-bottom: 0;
}

.its-tarifs_cell {
  width: calc(100% / 6);
  padding: 0 5px;
  text-align: center;
}

.its-tarifs_cell:nth-child(1) {
  flex-grow: 1;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: left;
}

.its-tarifs_cell:nth-child(1) img {
  margin-left: 12px;
  cursor: pointer;
}

.block-its-services {
  padding: 70px 0 100px;
  background: #F6F8FE;
}

.its-services_title {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: #2F2F69;
  max-width: 730px;
}

.its-services {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -18px -38px;
}

.its-service {
  width: calc(100% / 5 - 36px);
  margin: 0 18px 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 25px 20px;
  background: #ffffff;
  border-radius: 16px;
  min-height: 207px;
}

.its-service_img {
  width: 90px;
  height: 90px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(211.91deg, rgba(24, 115, 211, 0.1) 9.53%, rgba(24, 115, 211, 0) 117.86%);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.its-service_title {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

.block-its-about2 {
  padding: 70px 0 60px;
  background: #ffffff;
}

.block-its-about2 .its-about_title {
  color: #2F2F69;
  max-width: 693px;
}

.block-its-about2 .its-about_text p {
  color: rgba(0, 0, 0, 0.6);
}

.block-its-about2 .its-about_text p b {
  color: #000;
}

.block-its-about2 .its-about_text p strong {
  font-weight: 500;
}

.block-its-tarifs2 {
  padding: 0 0 90px;
}

.block-its-tarifs2 .its-tarifs_cell {
  width: 140px;
}

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

.wrapper {
  max-width: 1170px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (min-width: 1024px) {
  .license:hover {
    background: #4F98E4;
    transform: translateY(-15px);
  }

  .license:hover .license_title {
    color: #ffffff;
  }

  .license:hover svg path {
    fill: #ffffff;
    opacity: 1;
  }
}

@media screen and (max-width: 1170px) {
  main {
    padding-top: 91px;
  }

  header {
    padding: 15px 0;
  }

  .header_logo {
    max-width: 145px;
  }

  .offer_title {
    font-size: 40px;
    line-height: 130%;
  }

  .offer_text {
    font-size: 18px;
    line-height: 140%;
    max-width: 470px;
  }

  .offer_right svg {
    width: 800px;
    height: auto;
  }

  .about_left {
    font-size: 20px;
  }

  .license {
    width: calc(100% / 3 - 30px);
  }

  .page-title_name {
    font-size: 40px;
    line-height: 50px;
  }

  .block-page-title2 .page-title_img {
    margin-right: 30px;
  }

  .block-page-title3 .page-title_img {
    margin-right: 30px;
  }

  .block-its-offer {
    padding: 50px 0 100px;
  }

  .its-offer_title {
    font-size: 40px;
    line-height: 130%;
  }

  .its-offer_text {
    font-size: 18px;
    line-height: 140%;
  }

  .its-services {
    margin: 0 -15px -30px;
  }

  .its-service {
    margin: 0 15px 30px;
    width: calc(100% / 5 - 30px);
  }

  .wrapper {
    max-width: 1024px;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    flex-wrap: wrap;
  }

  .header_logo {
    margin-right: auto;
  }

  .header_nav {
    display: none;
    width: 100%;
    order: 1;
    margin-top: 30px;
  }

  .header_nav li {
    margin: 0 0 30px;
  }

  .header_nav li a {
    text-align: center;
    display: block;
    font-size: 14px;
  }

  .header_burger {
    display: block;
  }

  .footer_col:nth-child(4) {
    width: 250px;
  }

  .footer_phone {
    font-size: 24px;
    line-height: 30px;
    margin: -4px 0 17px;
  }

  .popUp_form {
    max-width: 350px;
    padding: 40px 30px;
  }

  .popUp_title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .popUp_inp {
    height: 45px;
    font-size: 14px;
  }

  .popUp_btn {
    margin: 10px auto 0;
    height: 45px;
  }

  .offer_title {
    font-size: 34px;
  }

  .offer_text {
    margin-bottom: 40px;
  }

  .offer_btns button {
    width: 200px;
    height: 50px;
  }

  .offer_right svg {
    width: 700px;
  }

  .news-item_title {
    font-size: 16px;
    line-height: 20px;
  }

  .block-licenses {
    padding: 70px 0 150px;
  }

  .block-eco {
    padding-bottom: 90px;
  }

  .eco_left p {
    font-size: 28px;
    line-height: 130%;
  }

  .eco_right p {
    font-size: 28px;
    line-height: 130%;
  }

  .block-projects {
    padding: 100px 0;
  }

  .project {
    width: calc(100% / 2 - 30px);
  }

  .project_link {
    opacity: 1;
  }

  .block-news-page {
    padding: 70px 0;
  }

  .page-title_name {
    font-size: 34px;
    line-height: 40px;
  }

  .block-page-title2 .page-title_name {
    font-size: 34px;
    line-height: 40px;
  }

  .news-single img {
    margin-bottom: 30px;
  }

  .news-single p {
    margin-bottom: 30px;
    font-size: 18px;
  }

  .product {
    padding: 30px 40px;
  }

  .block-its-offer {
    padding: 30px 0 80px;
  }

  .its-offer_title {
    font-size: 34px;
    max-width: 450px;
    margin-bottom: 25px;
  }

  .its-service {
    width: calc(100% / 4 - 30px);
    border-radius: 12px;
  }

  .wrapper {
    max-width: 920px;
  }
}

@media screen and (max-width: 920px) {
  .footer {
    flex-wrap: wrap;
  }

  .footer_col:nth-child(1) {
    order: 3;
    width: calc(50% - 15px);
  }

  .footer_col:nth-child(2) {
    order: 1;
    width: calc(50% - 15px);
  }

  .footer_col:nth-child(3) {
    order: 2;
    width: calc(50% - 15px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }

  .footer_col:nth-child(4) {
    order: 4;
    width: calc(50% - 15px);
  }

  .offer_title {
    font-size: 28px;
  }

  .offer_text {
    font-size: 16px;
    max-width: 440px;
  }

  .offer_btns button {
    font-size: 14px;
  }

  .offer_right svg {
    width: 600px;
  }

  .about_title {
    font-size: 34px;
    line-height: 40px;
  }

  .about_left {
    width: calc(100% - 30px);
    margin-bottom: 20px;
    font-size: 24px;
  }

  .about_right {
    width: calc(100% - 30px);
  }

  .block-news {
    padding: 70px 0;
  }

  .news_title {
    font-size: 34px;
    line-height: 40px;
  }

  .licenses_title {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 50px;
  }

  .eco {
    flex-direction: column;
  }

  .eco_left {
    justify-content: center;
    text-align: center;
    margin-bottom: 15px;
  }

  .eco_left span {
    margin: 0 4.5px;
  }

  .eco_right {
    justify-content: center;
    margin-top: 30px;
  }

  .eco_right p {
    text-align: center;
    order: 1;
    margin: 5px 0 0;
  }

  .eco_right span {
    margin: 0 4.5px;
  }

  .projects_title {
    font-size: 34px;
    line-height: 40px;
  }

  .news-page .news-item {
    width: calc(100% / 2 - 30px);
  }

  .block-page-title {
    padding: 15px 0 30px;
  }

  .page-title_img {
    width: 70px;
    height: 70px;
    border-radius: 20px;
    margin-right: 15px;
  }

  .page-title_img img {
    transform: scale(0.8);
  }

  .page-title_name {
    font-size: 30px;
    line-height: 36px;
  }

  .block-page-title2 .page-title_img {
    margin-right: 15px;
  }

  .block-page-title2 .page-title_name {
    font-size: 30px;
    line-height: 36px;
  }

  .products_title {
    font-size: 34px;
    line-height: 40px;
  }

  .products_text {
    font-size: 18px;
    line-height: 24px;
  }

  .contacts_left {
    padding: 25px 30px 25px 20px;
  }

  .contacts_phone {
    font-size: 28px;
    line-height: 36px;
  }

  .block-page-title3 .page-title_img {
    margin-right: 15px;
  }

  .its-offer_title {
    font-size: 28px;
  }

  .its-offer_text {
    font-size: 16px;
  }

  .its-about_title {
    font-size: 34px;
    line-height: 40px;
  }

  .its-about_text p {
    font-size: 16px;
  }

  .its-tarifs_row {
    font-size: 16px;
    line-height: 20px;
  }

  .its-service {
    width: calc(100% / 3 - 30px);
    padding: 20px 30px;
    min-height: auto;
  }

  .block-its-about2 .its-about_title {
    max-width: 620px;
  }

  .block-its-tarifs2 .its-tarifs_cell {
    width: 120px;
  }

  .wrapper {
    max-width: 768px;
  }
}

@media screen and (max-width: 768px) {
  main {
    padding-top: 85px;
  }

  .header_logo {
    max-width: 130px;
  }

  .header_btn {
    height: 50px;
  }

  .offer {
    flex-wrap: wrap;
  }

  .offer_left {
    width: calc(100% - 30px);
  }

  .offer_title {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .offer_text {
    margin-bottom: 30px;
  }

  .offer_right {
    width: calc(100% - 30px);
    margin-top: 40px;
  }

  .offer_right svg {
    width: 800px;
  }

  .feedback {
    padding: 40px 15px;
  }

  .feedback_text {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .feedback_title {
    font-size: 24px;
    max-width: 500px;
    margin-bottom: 20px;
  }

  .feedback_btn {
    height: 55px;
  }

  .block-eco {
    padding-bottom: 70px;
  }

  .block-projects {
    padding: 70px 0;
  }

  .project {
    width: calc(100% - 30px);
  }

  .page-title_img {
    width: 60px;
    height: 60px;
    border-radius: 15px;
  }

  .page-title_name {
    font-size: 24px;
    line-height: 30px;
  }

  .block-page-title2 .page-title_name {
    font-size: 24px;
    line-height: 30px;
  }

  .block-news-single {
    padding: 35px 0 70px;
  }

  .block-products {
    padding: 70px 0;
  }

  .product {
    width: calc(100% - 30px);
  }

  .products_more {
    margin-top: 70px;
  }

  .feedback-form {
    max-width: 450px;
  }

  .feedback-form_inp {
    height: 55px;
    padding: 17px 20px;
    margin-bottom: 25px;
  }

  .feedback-form_inp:nth-child(1),
  .feedback-form_inp:nth-child(2) {
    width: calc(100% / 2 - 12.5px);
  }

  .feedback-form textarea {
    height: 120px;
  }

  .feedback-form_btn {
    height: 55px;
  }

  .block-contacts {
    padding: 35px 0 30px;
  }

  .contacts_left {
    width: 100%;
  }

  .contacts_right {
    width: 100%;
  }

  .block-its-offer {
    padding: 0 0 70px;
  }

  .its-offer_left {
    width: calc(100% - 30px);
  }

  .its-offer_title {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .its-offer_right {
    width: calc(100% - 30px);
    order: -1;
  }

  .its-tarifs_row {
    font-size: 14px;
    line-height: 18px;
  }

  .block-its-services {
    padding: 70px 0;
  }

  .its-service {
    width: calc(100% / 2 - 30px);
  }

  .block-its-tarifs2 {
    padding: 0 0 70px;
  }

  .block-its-tarifs2 .its-tarifs_cell {
    width: 100px;
  }

  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .wrapper {
    max-width: 576px;
  }
}

@media screen and (max-width: 576px) {
  main {
    padding-top: 75px;
  }

  header {
    padding: 10px 0;
  }

  .header_btn {
    display: none;
    order: 2;
    margin: 0 auto 30px;
    transition: 0s;
  }

  .footer_col:nth-child(1) {
    flex-direction: row;
    align-items: center;
    order: -1;
    width: 100%;
  }

  .footer_col:nth-child(2) {
    align-items: flex-start;
    text-align: left;
  }

  .footer_col:nth-child(3) {
    align-items: flex-start;
    text-align: left;
  }

  .footer_col:nth-child(4) {
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .footer_logo img {
    height: 53px;
    margin-top: 4px;
  }

  .footer-bottom {
    flex-direction: column-reverse;
  }

  .footer-bottom_link {
    margin-bottom: 30px;
  }

  .popUp_content {
    padding: 40px 15px;
  }

  .offer_title {
    font-size: 28px;
    max-width: 470px;
  }

  .offer_right svg {
    width: 600px;
  }

  .block-about {
    padding: 60px 0;
  }

  .about_title {
    font-size: 24px;
    line-height: 32px;
  }

  .about_left {
    font-size: 18px;
  }

  .about_right p {
    font-size: 14px;
  }

  .block-news {
    padding: 60px 0;
  }

  .news_title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .news .arrows_left {
    width: 35px;
    height: 35px;
    margin-right: 15px;
  }

  .news .arrows_right {
    width: 35px;
    height: 35px;
  }

  .block-licenses {
    padding: 60px 0 120px;
  }

  .licenses_title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .license {
    width: calc(100% / 2 - 30px);
  }

  .block-eco {
    padding-bottom: 60px;
  }

  .eco_left p {
    font-size: 24px;
  }

  .eco_right p {
    font-size: 24px;
  }

  .block-projects {
    padding: 60px 0;
  }

  .projects_title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .block-news-page {
    padding: 60px 0;
  }

  .news-page {
    margin: 0 -15px -30px;
  }

  .block-news-single {
    padding: 35px 0 60px;
  }

  .news-single img {
    border-radius: 10px;
  }

  .news-single p {
    font-size: 16px;
  }

  .news-single .btn {
    height: 55px;
    width: 220px;
  }

  .block-products {
    padding: 60px 0;
  }

  .products_title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .products_text {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .products {
    margin-bottom: -30px;
  }

  .product {
    margin-bottom: 30px;
  }

  .products_more {
    margin-top: 60px;
  }

  .feedback-form {
    max-width: calc(100% - 30px);
  }

  .block-its-offer {
    padding: 0 0 60px;
  }

  .its-offer_title {
    font-size: 28px;
    max-width: 470px;
  }

  .its-about_title {
    font-size: 24px;
    line-height: 32px;
    max-width: 350px;
  }

  .its-about_text p {
    font-size: 14px;
  }

  .its-tarifs_title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .its-tarifs_table {
    margin: 0 -15px;
    padding: 0 15px;
  }

  .its-tarifs_row {
    width: 600px;
    margin-bottom: 0;
  }

  .block-its-services {
    padding: 60px 0;
  }

  .its-services_title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .block-its-about2 {
    padding: 60px 0;
  }

  .block-its-about2 .its-about_title {
    max-width: 450px;
  }

  .block-its-tarifs2 {
    padding: 0 0 60px;
  }
}

@media screen and (max-width: 500px) {
  .news-page .news-item {
    width: calc(100% - 30px);
    margin: 0 15px 30px;
  }
}

@media screen and (max-width: 450px) {
  .footer_col {
    margin-bottom: 30px;
  }

  .footer_col:nth-child(2) {
    width: calc(50% - 5px);
  }

  .footer_col:nth-child(3) {
    width: calc(50% - 5px);
  }

  .footer_nav li {
    margin-bottom: 20px;
  }

  .offer_title {
    font-size: 24px;
  }

  .offer_btns button:nth-child(1) {
    margin-right: 15px;
  }

  .feedback_title {
    font-size: 20px;
  }

  .eco_center {
    width: 345px;
  }

  .project-last_title {
    font-size: 20px;
    line-height: 26px;
  }

  .product {
    padding: 30px;
  }

  .product_img {
    margin-bottom: 20px;
  }

  .feedback-form_inp {
    margin-bottom: 15px;
  }

  .feedback-form_inp:nth-child(1),
  .feedback-form_inp:nth-child(2) {
    width: 100%;
  }

  .contacts_left {
    padding: 25px 15px;
  }

  .its-offer_title {
    font-size: 24px;
  }

  .its-services {
    margin: 0 -5px -15px;
  }

  .its-service {
    width: calc(100% / 2 - 10px);
    margin: 0 5px 15px;
    padding: 20px;
  }
}

@media screen and (max-width: 360px) {
  .footer_col:nth-child(2) {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .footer_col:nth-child(3) {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .offer_title {
    font-size: 20px;
  }

  .offer_text {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .offer_btns {
    flex-wrap: wrap;
  }

  .offer_btns button {
    width: 100%;
  }

  .offer_btns button:nth-child(1) {
    margin: 0 0 15px;
  }

  .license {
    width: calc(100% - 30px);
  }

  .eco_center {
    width: 290px;
  }

  .project {
    padding: 36px 30px 83px;
  }

  .page-title_img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }

  .page-title_img img {
    transform: scale(0.6);
  }

  .page-title_name {
    font-size: 20px;
    line-height: 24px;
  }

  .block-page-title2 .page-title_name {
    font-size: 20px;
    line-height: 24px;
  }

  .contacts_left {
    padding: 25px 5px;
  }

  .contacts_phone {
    font-size: 24px;
    line-height: 30px;
  }

  .contacts_mail {
    font-size: 18px;
    line-height: 24px;
  }

  .its-offer_title {
    font-size: 20px;
  }

  .its-offer_text {
    font-size: 14px;
  }

  .its-service_title {
    font-size: 13px;
    line-height: 16px;
  }
}
@font-face {
    font-family: "TT";
    src: url(../fonts/TTFirsNeue-Light.ttf);
    font-weight: 300; 
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "TT";
    src: url(../fonts/TTFirsNeue-Regular.ttf);
    font-weight: 400; 
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "TT";
    src: url(../fonts/TTFirsNeue-Medium.ttf);
    font-weight: 500; 
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "TT";
    src: url(../fonts/TTFirsNeue-DemiBold.ttf);
    font-weight: 600; 
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "TT";
    src: url(../fonts/TTFirsNeue-Bold.ttf);
    font-weight: 700; 
    font-style: normal;
    font-display: fallback;
}

*{
    box-sizing: border-box;
}

a, input, textarea, button{
    border: none;
    outline: none;
    background: none;
    transition: 0.5s;
    font-family: "TT";
    text-decoration: none;  
    padding: 0;
    color: inherit;
    cursor: pointer;
}

a:hover{
    text-decoration: none;
}

svg{
    path{
        transition: 0.5s;
    }
}

html{
    scroll-behavior: smooth;
}

body{
    font-family: "TT";
    color: @black;
    background: @white;
    line-height: 1;
    font-weight: 400;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    position: relative;
}

main{
    .lg-block({
        padding-top: 91px;
    });
    .sm-block({
        padding-top: 85px;
    });
    .xs-block({
        padding-top: 75px;
    });
    padding-top: 115px;
    max-width: 100%;
    overflow: hidden;
}

.btn{
    background: @blue;
    border-radius: 6px;
    .center();
    font-weight: 500;
    font-size: 16px;
    color: @white;
    &:hover{
        background: @darkblue;
    }
}

// header
header{
    .lg-block({
        padding: 15px 0;
    });
    .xs-block({
        padding: 10px 0;
    });
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    background: @white;
    padding: 24px 0;
    transition: 0.5s;
}

.header--scroll{
    padding: 10px 0;
}

.header{
    .xl-block({
        flex-wrap: wrap;
    });
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_logo{
        .lg-block({
            max-width: 145px;
        });
        .xl-block({
            margin-right: auto;
        });
        .sm-block({
            max-width: 130px;
        });
        max-width: 158px;
        flex-shrink: 0;
        img{
            width: 100%;
        }
    }
    &_nav{
        .xl-block({
            display: none;
            width: 100%;
            order: 1;
            margin-top: 30px;
        });
        display: flex;
        align-items: center;
        li{
            .xl-block({
                margin: 0 0 30px;
            });
            margin: 0 15px;
            a{
                .xl-block({
                    text-align: center;
                    display: block;
                    font-size: 14px;
                });
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.065em;
                text-transform: uppercase;
                color: #093B4E;
                &:hover{
                    color: @blue;
                }
            }
        }
    }
    &_btn{
        .sm-block({
            height: 50px;
        });
        .xs-block({
            display: none;
            order: 2;
            margin: 0 auto 30px;
            transition: 0s;
        });
        width: 175px;
        height: 51px;
        background: @darkblue;
        flex-shrink: 0;
        &:hover{
            background: @blue;
        }
    }
    &_burger{
        .xl-block({
            display: block;
        });
        display: none;
        position: relative;
        width: 30px;
        height: 20px;
        margin-left: 30px;
        cursor: pointer;
        span{
            display: block;
            width: 100%;
            position: absolute;
            background: @darkblue;
            height: 2px;
            left: 0;
            transition: 0.5s;
            &:nth-child(1){
                top: 0;
            }
            &:nth-child(2){
                top: 9px;
            }
            &:nth-child(3){
                top: 18px;
            }
        }
    }
    &_burger--active{
        span{
            &:nth-child(1){
                top: 9px;
                transform: rotate(45deg);
            }
            &:nth-child(2){
                opacity: 0;
            }
            &:nth-child(3){
                top: 9px;
                transform: rotate(-45deg);
            }
        }
    }
}

// footer
footer{
    background: @darkblue;
    padding-top: 175px;
}

.footer{
    .md-block({
        flex-wrap: wrap;
    });
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: #FFFFFF;
    &_col{
        .xxs-block({
            margin-bottom: 30px;
        });
        margin-bottom: 59px;
        &:nth-child(1){
            .md-block({
                order: 3;
                width: ~'calc(50% - 15px)';
            });
            .xs-block({
                flex-direction: row;
                align-items: center;
                order: -1;
                width: 100%;
            });
            width: 190px;
            align-self: stretch;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
        }
        &:nth-child(2){
            .md-block({
                order: 1;
                width: ~'calc(50% - 15px)';
            });
            .xs-block({
                align-items: flex-start;
                text-align: left;
            });
            .xxs-block({
                width: ~'calc(50% - 5px)';
            });
            .to(360px, {
                width: 100%;
                align-items: center;
                text-align: center;
            }); 
            width: 160px;
        }
        &:nth-child(3){
            .md-block({
                order: 2;
                width: ~'calc(50% - 15px)';
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                text-align: right;
            });
            .xs-block({
                align-items: flex-start;
                text-align: left;
            });
            .xxs-block({
                width: ~'calc(50% - 5px)';
            });
            .to(360px, {
                width: 100%;
                align-items: center;
                text-align: center;
            }); 
            width: 160px;
        }
        &:nth-child(4){
            .xl-block({
                width: 250px;
            });
            .md-block({
                order: 4;
                width: ~'calc(50% - 15px)';
            });
            .xs-block({
                align-items: center;
                text-align: center;
                width: 100%;
            });
            width: 310px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }
    &_logo{
        img{
            .xs-block({
                height: 53px;
                margin-top: 4px;
            });
        }
    }
    &_onec{
        margin-bottom: -10px;
    }
    &_title{
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 25px;
    }
    &_nav{
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.065em;
        text-transform: uppercase;
        li{
            .xxs-block({
                margin-bottom: 20px;
            });
            margin-bottom: 32px;
            &:last-child{
                margin-bottom: 0;
            }
            a{
                opacity: 0.8;
                &:hover{
                    opacity: 1;
                }
            }
        }
    }
    &_phone{
        .xl-block({
            font-size: 24px;
            line-height: 30px;
            margin: -4px 0 17px;
        });
        font-weight: 600;
        font-size: 32px;
        line-height: 41px;
        margin: -4px 0 6px;
        display: block;
        &:hover{
            color: @blue;
        }
    }
    &_mail{
        display: block;
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 24px;
        &:hover{
            color: @blue;
        }
    }
    &_btn{
        width: 190px;
        height: 51px;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid @white;
        margin-bottom: 33px;
    }
    &_address{
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        opacity: 0.8;
        &:hover{
            opacity: 1;
        }
    }
}

.footer-bottom{
    .xs-block({
        flex-direction: column-reverse;
    });
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px 0;
    border-top: 1px solid rgba(255,255,255,0.2);
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.065em;
    text-transform: uppercase;
    color: #FFFFFF;
    &_copy{
        opacity: 0.7;
    }
    &_link{
        .xs-block({
            margin-bottom: 30px;
        });
        opacity: 0.7;
        &:hover{
            opacity: 1;
        }
    }
}

// popUp
.popUp{
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    &::-webkit-scrollbar-thumb {
        width: 0;
        height: 0;
    }
    &_content{
        .xs-block({
            padding: 40px 15px;
        });
        position: relative;
        z-index: 99;
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        .center();
        flex-wrap: wrap;
        padding: 40px 30px;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        &::-webkit-scrollbar-thumb {
            width: 0;
            height: 0;
        }
    }
    &_form{
        .xl-block({
            max-width: 350px;
            padding: 40px 30px;
        });
        max-width: 515px;
        width: 100%;
        background: @white;
        position: relative;
        z-index: 99;
        padding: 65px 40px;
    }
    &_cross{
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        &:hover{
            svg{
                path{
                    fill: @blue;
                }
            }
        }        
    }
    &_title{
        .xl-block({
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 20px;
        });
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        text-align: center;
        letter-spacing: 0.1em;
        margin-bottom: 30px;
    }
    form{
        text-align: center;
    }
    &_inp{
        .xl-block({
            height: 45px;
            font-size: 14px;
        });
        background: @white;
        width: 100%;
        max-width: 350px;
        height: 50px;
        font-size: 16px;
        font-weight: 400;
        padding: 0 20px;
        margin: 0 auto 15px;
        color: @black;
        border: 1px solid @darkblue;
        &::placeholder{
            color: #C4C4C4;
        }
    }
    &_btn{
        .xl-block({
            margin: 10px auto 0;
            height: 45px;
        });
        height: 50px;
        width: 100%;
        max-width: 350px;
        margin: 20px auto 0;
    }
    &_layer{
        position: fixed;
        z-index: 98;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.6);
        cursor: pointer;
    }
}

// offer
.block-offer{
    padding: 15px 0 60px;
}

.offer{
    .sm-block({
        flex-wrap: wrap;
    });
    display: flex;
    align-items: center;
    margin: 0 -15px;
    &_left{
        .sm-block({
            width: ~'calc(100% - 30px)';
        });
        width: ~'calc(100% / 12 * 7 - 30px)';
        margin: 0 15px;
    }
    &_title{
        .lg-block({
            font-size: 40px;
            line-height: 130%;
        });
        .xl-block({
            font-size: 34px;
        });
        .md-block({
            font-size: 28px;
        });
        .sm-block({
            font-size: 32px;
            margin-bottom: 20px;
        });
        .xs-block({
            font-size: 28px;
            max-width: 470px;
        });
        .xxs-block({
            font-size: 24px;
        });
        .xxxs-block({
            font-size: 20px;
        });
        font-weight: 500;
        font-size: 44px;
        line-height: 57px;
        color: @darkblue;
        margin-bottom: 30px;
    }
    &_text{
        .lg-block({
            font-size: 18px;
            line-height: 140%;
            max-width: 470px;
        });
        .xl-block({
            margin-bottom: 40px;
        });
        .md-block({
            font-size: 16px;
            max-width: 440px;
        });
        .sm-block({
            margin-bottom: 30px;
        });
        .xxxs-block({
            font-size: 14px;
            margin-bottom: 20px;
        });
        font-size: 22px;
        line-height: 28px;
        color: @darkgrey;
        opacity: 0.5;
        margin-bottom: 60px;
        max-width: 568px;
    }
    &_btns{
        .xxxs-block({
            flex-wrap: wrap;
        });
        display: flex;
        button{
            .xl-block({
                width: 200px;
                height: 50px;
            });
            .md-block({
                font-size: 14px;
            });
            .xxxs-block({
                width: 100%;
            });
            width: 221px;
            height: 66px;
            &:nth-child(1){
                .xxs-block({
                    margin-right: 15px;
                });
                .xxxs-block({
                    margin: 0 0 15px;
                });
                margin-right: 30px;
            }
            &:nth-child(2){
                color: @blue;
                background: @lightblue;
                &:hover{
                    color: @white;
                    background: @darkblue;
                }
            }
        }
    }
    &_right{
        .sm-block({
            width: ~'calc(100% - 30px)';
            margin-top: 40px;
        });
        width: ~'calc(100% / 12 * 5 - 30px)';
        margin: 0 15px;
        svg{
            .lg-block({
                width: 800px;
                height: auto;
            });
            .xl-block({
                width: 700px;
            });
            .md-block({
                width: 600px;
            });
            .sm-block({
                width: 800px;
            });
            .xs-block({
                width: 600px;
            });
            width: 894px;
            max-width: none;
        }
    }
}

// about
.block-about{
    .xs-block({
        padding: 60px 0;
    });
    background: @blue;
    padding: 76px 0;
}

.about_title{
    .md-block({
        font-size: 34px;
        line-height: 40px;
    });
    .xs-block({
        font-size: 24px;
        line-height: 32px;
    });
    font-weight: 500;
    font-size: 38px;
    line-height: 49px;
    margin-bottom: 26px;
    color: @white;
}

.about{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -15px;
    color: @white;
    &_left{
        .lg-block({
            font-size: 20px;
        });
        .md-block({
            width: ~'calc(100% - 30px)';
            margin-bottom: 20px;
            font-size: 24px;
        });
        .xs-block({
            font-size: 18px;
        });
        width: ~'calc(100% / 2 - 30px)';
        margin: 0 15px;
        font-size: 23px;
        line-height: 150%;
        a{
            text-decoration: underline;
            &:hover{
                color: @darkblue;
            }
        }
    }
    &_right{
        .md-block({
            width: ~'calc(100% - 30px)';
        });
        width: ~'calc(100% / 2 - 30px)';
        margin: 0 15px;
        p{
            .xs-block({
                font-size: 14px;
            });
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

// news
.block-news{
    .md-block({
        padding: 70px 0;
    });
    .xs-block({
        padding: 60px 0;
    });
    padding: 90px 0 100px;
    background: #F6F8FE;
}

.news{
    &_title{
        .md-block({
            font-size: 34px;
            line-height: 40px;
        });
        .xs-block({
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 20px;
        });
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 38px;
        line-height: 49px;
        color: @darkblue;
    }
    .arrows{
        flex-shrink: 0;
        display: flex;
        align-items: center;
        &_left{
            .xs-block({
                width: 35px;
                height: 35px;
                margin-right: 15px;
            });
            width: 40px;
            height: 40px;
            .center();
            border-radius: 50%;
            border: 2px solid @blue;
            margin-right: 20px;
            padding-right: 2px;
            transition: 0.5s;
            cursor: pointer;
            &:hover{
                background: @blue;
                svg{
                    path{
                        fill: @white;
                    }
                }
            }
        }
        &_right{
            .xs-block({
                width: 35px;
                height: 35px;
            });
            width: 40px;
            height: 40px;
            .center();
            border-radius: 50%;
            border: 2px solid @blue;
            padding-left: 2px;
            transition: 0.5s;
            cursor: pointer;
            svg{
                path{
                    fill: @darkblue;
                }
            }
            &:hover{
                background: @blue;
                svg{
                    path{
                        fill: @white;
                    }
                }
            }
        }
    }
}

.news-slider{
    margin: 0 -15px;
    .slick-slide{
        margin: 0 15px;
    }
    .slick-track{
        display: flex;
    }
}

.news-item{
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    &_img{
        width: 100%;
        border-radius: 16px;
        overflow: hidden;
        margin-bottom: 16px;
        img{
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
    &_date{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 133.5%;
        color: rgba(0, 0, 0, 0.6);
        img{
            margin-right: 16px;
            margin-top: -4px;
        }
    }
    &_title{
        .xl-block({
            font-size: 16px;
            line-height: 20px;
        });
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        margin-bottom: 26px;
        display: block;
        &:hover{
            color: @blue;
        }
    }
    &_link{
        margin-top: auto;
        font-weight: 600;
        font-size: 14px;
        line-height: 133.5%;
        color: @blue;
        svg{
            margin-left: 9px;
            margin-bottom: -2px;
        }
        &:hover{
            color: @darkblue;
            svg{
                path{
                    fill: @darkblue;
                }
            }
        }
    }
}

// feedback
.block-feedback{
    background: #F6F8FE;
}

.block-feedback2{
    background: @white;
}

.feedback{
    .sm-block({
        padding: 40px 15px;
    });
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
    background-color: @blue;
    background-image: url('../img/photo/5.png');
    background-position: left 30px bottom;
    background-repeat: no-repeat;
    background-size: 191px 273px;
    max-width: 823px;
    margin: 0 auto;
    box-shadow: 2px 20px 30px rgba(0, 0, 78, 0.15);
    border-radius: 24px;
    margin-bottom: -125px;
    position: relative;
    z-index: 1;
    color: @white;
    &_text{
        .sm-block({
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 20px;
        });
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        opacity: 0.8;
        margin-bottom: 32px;
    }
    &_title{
        .sm-block({
            font-size: 24px;
            max-width: 500px;
            margin-bottom: 20px;
        });
        .xxs-block({
            font-size: 20px;
        });
        font-weight: 500;
        font-size: 32px;
        line-height: 133.5%;
        text-align: center;
        margin-bottom: 32px;
        max-width: 607px;
    }
    &_btn{
        .sm-block({
            height: 55px;
        });
        background-color: @darkblue;
        width: 191px;
        height: 64px;
        &:hover{
            background: @white;
            color: @darkblue;
        }
    }
}

// licenses
.block-licenses{
    .xl-block({
        padding: 70px 0 150px;
    });
    .xs-block({
        padding: 60px 0 120px;
    });
    padding: 90px 0 240px;
}

.licenses_title{
    .md-block({
        font-size: 34px;
        line-height: 40px;
        margin-bottom: 50px;
    });
    .xs-block({
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
    });
    margin-bottom: 85px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 38px;
    line-height: 49px;
    color: @darkblue;
}

.licenses{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -30px;
}

.license{
    .lg-block({
        width: ~'calc(100% / 3 - 30px)';
    });
    .xs-block({
        width: ~'calc(100% / 2 - 30px)';
    });
    .xxxs-block({
        width: ~'calc(100% - 30px)';
    });
    width: ~'calc(100% / 6 - 30px)';
    margin: 0 15px 30px;
    box-shadow: 0px 5px 6px rgba(0, 0, 78, 0.04);
    background: @white;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    transition: 0.5s;
    &:hover{
        .from(1024px, {
            background: @blue;
            transform: translateY(-15px);
        });
        .license_title{
            .from(1024px, {
                color: @white;
            });
        }
        svg{
            path{
                .from(1024px, {
                    fill: @white;
                    opacity: 1;
                });
            }
        }
    }
    &_icon{
        margin-bottom: 60px;
    }
    &_inf{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: @darkblue;
        min-height: 36px;
        width: 100%;
        svg{
            width: 24px;
            flex-shrink: 0;
            margin-left: 10px;
        }
    }
    &_title{
        transition: 0.5s;
    }
}

.license--active{
    background: @blue;
    .license_title{
        color: @white;
    }
    svg{
        path{
            fill: @white;
            opacity: 1;
        }
    }
}

.tooltipster-content{
    background: #E8F1FB;
    border-radius: 8px;
    border: none !important;
    padding: 18px 20px !important;
    font-size: 16px;
    line-height: 21px !important;
    color: rgba(0, 0, 0, 0.7) !important;
}

.tooltipster-sidetip .tooltipster-box{
    border: none !important;
    background: transparent;
}

.tooltipster-arrow{
    border: none !important;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border{
    border-bottom-color: #E8F1FB;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background{
    border: none !important;
}

// eco
.block-eco{
    .xl-block({
        padding-bottom: 90px;
    });
    .sm-block({
        padding-bottom: 70px;
    });
    .xs-block({
        padding-bottom: 60px;
    });
    padding-bottom: 120px;
}

.eco{
    .md-block({
        flex-direction: column;
    });
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_left{
        .md-block({
            justify-content: center;
            text-align: center;
            margin-bottom: 15px;
        });
        width: 230px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        p{
            .xl-block({
                font-size: 28px;
                line-height: 130%;
            });
            .xs-block({
                font-size: 24px;
            });
            width: 100%;
            margin-bottom: 5px;
            font-weight: 500;
            font-size: 38px;
            line-height: 49px;
            color: @darkblue;
        }
        span{
            .md-block({
                margin: 0 4.5px;
            });
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: @blue;
            margin-right: 9px;
        }
    }
    &_center{
        .xxs-block({
            width: 345px;
        });
        .xxxs-block({
            width: 290px;
        });
        width: 422px;
        flex-shrink: 0;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        .gif{
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
        }
        img{
            position: relative;
            z-index: 2;
            width: 100%;
        }
    }
    &_right{
        .md-block({
            justify-content: center;
            margin-top: 30px;
        });
        width: 230px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        p{
            .xl-block({
                font-size: 28px;
                line-height: 130%;
            });
            .md-block({
                text-align: center;
                order: 1;
                margin: 5px 0 0;
            });
            .xs-block({
                font-size: 24px;
            });
            width: 100%;
            margin-bottom: 5px;
            font-weight: 500;
            font-size: 38px;
            line-height: 49px;
            color: @darkblue;
            text-align: right;
        }
        span{
            .md-block({
                margin: 0 4.5px;
            });
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: @blue;
            margin-left: 9px;
        }
    }
}

// project
.block-projects{
    .xl-block({
        padding: 100px 0;
    });
    .sm-block({
        padding: 70px 0;
    });
    .xs-block({
        padding: 60px 0;
    });
    padding: 120px 0 150px;
    background: #F6F8FE;
}

.projects_title{
    .md-block({
        font-size: 34px;
        line-height: 40px;
    });
    .xs-block({
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
    });
    font-weight: 500;
    font-size: 38px;
    line-height: 49px;
    text-align: center;
    color: @darkblue;
    margin-bottom: 50px;
}

.projects{
    margin: 0 -15px -30px;
    display: flex;
    flex-wrap: wrap;
}

.project{
    .xl-block({
        width: ~'calc(100% / 2 - 30px)';
    });
    .sm-block({
        width: ~'calc(100% - 30px)';
    });
    .xxxs-block({
        padding: 36px 30px 83px;
    });
    width: ~'calc(100% / 3 - 30px)';
    margin: 0 15px 30px;
    background: @white;
    border-radius: 16px;
    padding: 36px 41px 83px;
    position: relative;
    overflow: hidden;
    transition: 0.5s;
    &:hover{
        background: @blue;
        transform: translateY(-15px);
        .project_img{
            img{
                &:nth-child(1){
                    opacity: 0;
                }
                &:nth-child(2){
                    opacity: 1;
                }
            }
        }
        .project_title, .project_text{
            color: @white;
        }
        .project_link{
            opacity: 1;
        }
    }
    &_img{
        width: 90px;
        height: 90px;
        border: 1px solid rgba(255,255,255,0.5);
        background: linear-gradient(211.91deg, rgba(24, 115, 211, 0.1) 9.53%, rgba(24, 115, 211, 0) 117.86%);
        border-radius: 25px;
        .center();
        margin-bottom: 32px;
        position: relative;
        img{
            transition: 0.5s;
            &:nth-child(1){
                opacity: 1;
            }
            &:nth-child(2){
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
    &_title{
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: @black;
        margin-bottom: 16px;
        transition: 0.5s;
    }
    &_text{
        font-size: 14px;
        line-height: 133.5%;
        color: @black;
        opacity: 0.6;
        transition: 0.5s;
    }
    &_link{
        .xl-block({
            opacity: 1;
        });
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 57px;
        padding: 0 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        font-size: 14px;
        line-height: 133.5%;
        color: @white;
        background: @darkblue;
        opacity: 0;
        svg{
            path{
                fill: @white;
            }
        }
    }
}

.project-last{
    border: 2px dashed rgba(47, 47, 105, 0.5);
    background: transparent !important;
    transform: none !important;
    &_img{
        background: none;
        border-radius: 0;
        width: ~'calc(100% + 82px)';
        margin-left: -41px;
        border: none;
        margin-bottom: 26px;
        img{
            opacity: 1 !important;
        }
    }
    &_title{
        .xxs-block({
            font-size: 20px;
            line-height: 26px;
        });
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        color: @black !important;
    }
    &_link{
        width: ~'calc(100% - 42px)';
        left: 22px;
        opacity: 1;
        background: #FFFFFF;
        border-radius: 16px;
        color: @darkblue;
        bottom: 22px;
        svg{
            path{
                fill: @darkblue;
            }
        }
        &:hover{
            background: @blue;
            color: @white;
            svg{
                path{
                    fill: @white;
                }
            }
        }
    }
}
@import "smart-grid.less";
@import "slick.less";
@import "slick-theme.less";
@import "tooltipster.bundle.min.less";
@import "project.less";
@import "news.less";
@import "products.less";
@import "contacts.less";
@import "its.less";

.reset();

.wrapper{
    .wrapper();
    .lg-block({
        max-width: 1024px;
    });
    .xl-block({
        max-width: 920px;
    });
    .md-block({
        max-width: 768px;
    });
    .sm-block({
        max-width: 576px;
    });
}

.center(){
    display: flex;
    align-items: center;
    justify-content: center;
}

@white: #ffffff;
@black: #000;
@darkblue: #2F2F69;
@blue: #4F98E4;
@lightblue: rgba(79, 152, 228, 0.2);
@darkgrey: #161616;
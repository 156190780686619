.block-its-offer{
    .lg-block({
        padding: 50px 0 100px;
    });
    .xl-block({
        padding: 30px 0 80px;
    });
    .sm-block({
        padding: 0 0 70px;
    });
    .xs-block({
        padding: 0 0 60px;
    });
    padding: 60px 0 130px;
}

.its-offer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -15px;
    &_left{
        .sm-block({
            width: ~'calc(100% - 30px)';
        });
        margin: 0 15px;
        width: ~'calc(100% / 12 * 7 - 30px)';
    }
    &_title{
        .lg-block({
            font-size: 40px;
            line-height: 130%;
        });
        .xl-block({
            font-size: 34px;
            max-width: 450px;
            margin-bottom: 25px;
        });
        .md-block({
            font-size: 28px;
        });
        .sm-block({
            font-size: 32px;
            margin-bottom: 20px;
        });
        .xs-block({
            font-size: 28px;
            max-width: 470px;
        });
        .xxs-block({
            font-size: 24px;
        });
        .xxxs-block({
            font-size: 20px;
        });
        font-weight: 500;
        font-size: 44px;
        line-height: 57px;
        color: @darkblue;
        max-width: 600px;
        margin-bottom: 38px;
    }
    &_text{
        .lg-block({
            font-size: 18px;
            line-height: 140%;
        });
        .md-block({
            font-size: 16px;
        });
        .xxxs-block({
            font-size: 14px;
        });
        font-size: 22px;
        line-height: 28px;
        color: @darkblue;
        opacity: 0.6;
    }
    &_right{
        .sm-block({
            width: ~'calc(100% - 30px)';
            order: -1;
        });
        margin: 0 15px;
        width: ~'calc(100% / 12 * 5 - 30px)';
        text-align: center;
        img{
            width: 100%;
            max-width: 342px;
        }
    }
}

.block-its-about{
    padding: 60px 0;
    background: @blue;
    color: #FFFFFF;
}

.its-about_title{
    .md-block({
        font-size: 34px;
        line-height: 40px;
    });
    .xs-block({
        font-size: 24px;
        line-height: 32px;
        max-width: 350px;
    });
    font-weight: 500;
    font-size: 38px;
    line-height: 49px;
    margin-bottom: 26px;
    max-width: 490px;
}

.its-about_text{
    max-width: 740px;
    p{
        .md-block({
            font-size: 16px;
        });
        .xs-block({
            font-size: 14px;
        });
        font-size: 18px;
        line-height: 150%;
        color: rgba(255,255,255,0.8);
        margin-bottom: 26px;
        b{
            color: @white;
            font-weight: 700;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.block-its-tarifs{
    padding: 60px 0;
}

.its-tarifs{
    &_title{
        .xs-block({
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 20px;
        });
        margin-bottom: 50px;
        font-weight: 500;
        font-size: 32px;
        line-height: 42px;
        color: @darkblue;
        max-width: 730px;
    }
}

.its-tarifs_table{
    .xs-block({
        margin: 0 -15px;
        padding: 0 15px;
    });
    overflow-x: scroll;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    &::-webkit-scrollbar-thumb {
        width: 0;
        height: 0;
    }
}

.its-tarifs_row{
    .md-block({
        font-size: 16px;
        line-height: 20px;
    });
    .sm-block({
        font-size: 14px;
        line-height: 18px;
    });
    .xs-block({
        width: 600px;
        margin-bottom: 0;
    });
    padding: 16px 18px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    border-radius: 5px;
    font-size: 18px;
    line-height: 23px;
    &:nth-child(1){
        color: @darkblue;
        font-weight: 500;
    }
    &:nth-child(even){
        background: #F6F8FE;
    }
    &:last-child{
        margin-bottom: 0;
    }
}

.its-tarifs_cell{
    width: ~'calc(100% / 6)';
    padding: 0 5px;
    text-align: center;
    &:nth-child(1){
        flex-grow: 1;
        font-weight: 500;
        display: flex;
        align-items: center;
        text-align: left;
        img{
            margin-left: 12px;
            cursor: pointer;
        }
    }
}

.block-its-services{
    .sm-block({
        padding: 70px 0;
    });
    .xs-block({
        padding: 60px 0;
    });
    padding: 70px 0 100px;
    background: #F6F8FE;
}

.its-services_title{
    .xs-block({
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
    });
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
    color: @darkblue;
    max-width: 730px;
}

.its-services{
    .lg-block({
        margin: 0 -15px -30px;
    });
    .xxs-block({
        margin: 0 -5px -15px;
    });
    display: flex;
    flex-wrap: wrap;
    margin: 0 -18px -38px;
}

.its-service{
    .lg-block({
        margin: 0 15px 30px;
        width: ~'calc(100% / 5 - 30px)';
    });
    .xl-block({
        width: ~'calc(100% / 4 - 30px)';
        border-radius: 12px;
    });
    .md-block({
        width: ~'calc(100% / 3 - 30px)';
        padding: 20px 30px;
        min-height: auto;
    });
    .sm-block({
        width: ~'calc(100% / 2 - 30px)';
    });
    .xxs-block({
        width: ~'calc(100% / 2 - 10px)';
        margin: 0 5px 15px;
        padding: 20px;
    });
    width: ~'calc(100% / 5 - 36px)';
    margin: 0 18px 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 25px 20px;
    background: @white;
    border-radius: 16px;
    min-height: 207px;
    &_img{
        width: 90px;
        height: 90px;
        border: 1px solid rgba(255,255,255,0.5);
        background: linear-gradient(211.91deg, rgba(24, 115, 211, 0.1) 9.53%, rgba(24, 115, 211, 0) 117.86%);
        border-radius: 25px;
        .center();
        margin-bottom: 20px;
    }
    &_title{
        .xxxs-block({
            font-size: 13px;
            line-height: 16px;
        });
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
    }
}

.block-its-about2{
    .xs-block({
        padding: 60px 0;
    });
    padding: 70px 0 60px;
    background: @white;
    .its-about_title{
        .md-block({
            max-width: 620px;
        });
        .xs-block({
            max-width: 450px;
        });
        color: @darkblue;
        max-width: 693px;
    }
    .its-about_text{
        p{
            color: rgba(0,0,0,0.6);
            b{
                color: @black;
            }
            strong{
                font-weight: 500;
            }
        }
    }
}

.block-its-tarifs2{
    .sm-block({
        padding: 0 0 70px;
    });
    .xs-block({
        padding: 0 0 60px;
    });
    padding: 0 0 90px;
    .its-tarifs_cell{
        .md-block({
            width: 120px;
        });
        .sm-block({
            width: 100px;
        });
        width: 140px;
    }
}